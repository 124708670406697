import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  constructor(private http:HttpClient) { }
  
  get(type,filters){
   return this.http.post<any>(`jobs.php?type=${type}`,filters);
  }

}
