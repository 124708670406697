import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import json_decode from "jwt-decode";
import { BehaviorSubject, Observable } from 'rxjs';
import {Router, RouterModule} from '@angular/router'

import { map } from 'rxjs/operators';

import { User } from '../models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private sessionUserSubject: BehaviorSubject<User>;
    public sessionUser: Observable<User>;
    isLoginSubject = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient,private router:Router) {
            let session=localStorage.getItem("sessionUser")?<User>json_decode(localStorage.getItem("sessionUser")):null;
            this.sessionUserSubject = new BehaviorSubject<User>(session);
            this.sessionUser = this.sessionUserSubject.asObservable();
        
        
    }

    public get sessionUserValue(): User {
        return this.sessionUserSubject?this.sessionUserSubject.value:null;
    }

    login(email: string, password: string) {
        return this.http.post<any>(`login.php`, { email, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user.success && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('sessionUser', user.token);
                    let session=JSON.parse(JSON.stringify(json_decode(user.token)));
                    session.token=user.token
                    this.sessionUserSubject.next(session);
                }

                return user;
            }));
    }

    logout() {
        localStorage.removeItem('sessionUser');
        this.sessionUserSubject.next(null);
        this.router.navigate(['/login'])
    }

    getSession(){
        let session=localStorage.getItem('sessionUser');
        if(session){
           
        }else{
            return {};
        }

    }
    
        isLoggedIn(){
            return localStorage.getItem('sessionUser')?true:false;
          }
    
}