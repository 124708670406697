import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpService implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let sessionUser = localStorage.getItem('sessionUser');
        let baseUrl = `https://stryki.com/includes/process/`;
        if (sessionUser) {
            request = request.clone({
                url: `${baseUrl}${request.url}`,
                setHeaders: {
                    Authorization: `Bearer ${sessionUser}`
                }
            });
        } else {
            request = request.clone({ url: `${baseUrl}${request.url}` });
        }
        return next.handle(request);
    }
}