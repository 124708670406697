import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './helpers';

const routes: Routes = [ 
{ path: 'login', component: LoginComponent },
{ path: '', component: DashboardComponent, loadChildren: () => import(`./dashboard/dashboard.module`).then(dashboard => dashboard.DashboardModule), canActivate: [AuthGuard] },
{ path: '**', redirectTo: '' },];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
