import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service'
import { User } from '../models'
import { Subscription } from 'rxjs'
@Component({
  selector: 'app-siderbar',
  templateUrl: './siderbar.component.html'
})
export class SiderbarComponent implements OnInit {
  collapsed: boolean = true;
  sessionUser: User;
  sessionUserSubscription: Subscription;
  constructor(private authService:AuthenticationService) {
    this.sessionUserSubscription = this.authService.sessionUser.subscribe(user => {
      this.sessionUser = user;
  });
   }

  ngOnInit() {
  }

  menuExpand(){
    this.collapsed = !this.collapsed;       
  }

}
