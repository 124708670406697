import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({ providedIn: 'root' })
export class ProfileService {
    constructor(private http: HttpClient) { }

    get() {
        return this.http.get<any>(`profile.php?type=get`);
    }
    save(body) {
        return this.http.post<any>(`settings.php?type=settings`,body);
    }

    changePassword(body){
        return this.http.put<any>(`changePassword.php`,body);
    }
    delete(id: number) {
        return this.http.delete<any>(`user/${id}`);
    }

}