import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service'
import { User } from '../models'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  sessionUser: User;
  sessionUserSubscription: Subscription;
  
  constructor(private authService:AuthenticationService) {
    this.sessionUserSubscription = this.authService.sessionUser.subscribe(user => {
      this.sessionUser = user;
  });
  }

  ngOnInit() {
  }

}
